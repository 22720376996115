import React, { useContext } from 'react'
import { Context } from '../..';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

const MenuCatalog = observer(({item}) => {
    const {app, productCategories} = useContext(Context);

  return (
    <div className="main-nav navbar navbar-expand-xl navbar-expand-xl-none navbar-light navbar-sticky">
        <div className="offcanvas offcanvas-collapse order-xl-2" id="primaryMenu">
            <div className="offcanvas-header navbar-shadow">
                <h5></h5>
                <button className="btn-close lead" type="button" ></button>
            </div>
            <div className="offcanvas-body">
                <ul className="navbar-nav">
                    {productCategories?.categories?.map((card, idnex) =>
                        card.children?.length >= 1 ?
                            <li className="nav-item dropdown" key={card.slug}>
                                <NavLink to={`/${app.lang}/catalog/${card.slug}`} className="nav-link dropdown-toggle" data-bs-toggle="dropdown">{card.title}</NavLink>
                                <ul className="dropdown-menu">
                                    {card.children?.map((el, ind) =>
                                        el?.children?.length >= 1 ?
                                            <li className="sub-dropdown-hover" key={ind}>
                                                <NavLink to={`/${app.lang}/catalog/${el.slug}`} className="dropdown-item">{el.title}</NavLink>
                                                <ul className="sub-menu">
                                                    {el?.children?.map((it, inde) =>
                                                        <li key={inde}>
                                                            <a href="product-left-thumbnail.html">{it.title}</a>
                                                        </li>
                                                    )}
                                                </ul>
                                            </li> 
                                        :
                                         <li key={ind}>
                                            <NavLink to={`/${app.lang}/catalog/${el.slug}`} className="dropdown-item">{el.title}</NavLink>
                                        </li>
                                    )}
                                </ul>
                                
                            </li>
                        :
                        <li className="nav-item dropdown" key={card.slug}>
                            <NavLink to={`/${app.lang}/${card.slug}`} className="nav-link dropdown-toggle nav-none">{card.title}</NavLink>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    </div>
  )
})

export default MenuCatalog